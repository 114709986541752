import React from 'react'

const ContactNumber = () => {
  return (
    <div>
      <h1>This is my contact number 1234-5678</h1>
    </div>
  )
}

export default ContactNumber
