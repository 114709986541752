import React from 'react'

const Home = () => {
  return (
    <div>
      <h1>This is my home page - part 3</h1>
    </div>
  )
}

export default Home
